.filter-dropdown {
  position: absolute;
  z-index: 10;
  width: 100%;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 0 0 6px 6px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  left: 0;
}
.filter-dropdown.active {
  opacity: 1;
  transition: all 0.3s ease-in-out;
  pointer-events: all;
}

/********** filter scrollbar ********/
.custom-scrollbar-horizontal::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: #f5f5f5;
}

.custom-scrollbar-horizontal::-webkit-scrollbar {
  height: 7px;
  background-color: #f5f5f5;
}

.custom-scrollbar-horizontal::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4e4e4e;
}
.hidden-scrollbar-horizontal::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: #f5f5f5;
}

.hidden-scrollbar-horizontal::-webkit-scrollbar {
  height: 0px;
  background-color: #f5f5f5;
}

.hidden-scrollbar-horizontal::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4e4e4e;
}

.filter-radio {
  width: 0;
}
.filter-radio:checked + label {
  font-weight: 600;
}


.filter-asset-manager-checkbox {
  accent-color: rgb(255, 255, 255);
  width: 0;
  appearance: none;
}

.filter-asset-manager-checkbox:checked + label {
  font-weight: 500;
}
span:has(.filter-asset-manager-checkbox) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.filter-asset-manager-checkbox + label {
  display: flex;
  align-items: center;
}
.filter-asset-manager-checkbox + label .checked-icon {
  display: none;
}
.filter-asset-manager-checkbox:checked + label .checked-icon {
  display: inline;
}
.filter-asset-manager-checkbox:checked + label .unchecked-icon {
  display: none;
}
