.carousel__container {
  /* max-width: 100%; */
  /* margin: auto; */
  /* border: 2px solid black; */
}

.sports-swimlane-slider-class .carousel__slider {
  padding-left: 0% !important;
  padding-right: 0% !important;
  /* border: 2px solid rgb(181, 231, 243); */
}

/* gives us the illusion of a "centered" slide */
.swimlane-slider-class .carousel__slider {
  padding-left: 5.52% !important;
  padding-right: 5.52% !important;
  /* border: 2px solid rgb(181, 231, 243); */
}

/* gives us the illusion of spaces between the slides */
.swimlane-slider-class .carousel__inner-slide {
  width: 100% !important;
  /* height: 100%; */
  padding: 0 5px;
}

.swimlane-slider-class .swimlane-container-dock {
  position: relative;
  max-width: 100%;
  /*height: 14rem;*/
  z-index: 10;
}

.swimlane-slider-class .swimlane-container-hover {
  position: relative;
  max-width: 100%;
  height: auto !important;
  z-index: 40;
}

/* gives us the illusion of a "centered" slide */
.portrait-owl-slider-class .carousel__slider {
  padding-left: 5.52% !important;
  padding-right: 3% !important;
  /* border: 2px solid rgb(181, 231, 243); */
}

/* gives us the illusion of spaces between the slides */
.portrait-owl-slider-class .carousel__inner-slide {
  width: 100% !important;
  /* height: 100%; */
  padding: 0 5px;
}

.portrait-owl-slider-class .swimlane-container-dock {
  position: relative;
  max-width: 100%;
  /*height: 14rem;*/
  z-index: 10;
}

.portrait-owl-slider-class .swimlane-container-hover {
  position: relative;
  max-width: 100%;
  height: auto !important;
  z-index: 40;
}

.horizontalSlider___281Ls {
  /* position: unset !important; */
  /* overflow: auto !important;
  overflow-y: visible !important;
  overflow-x: hidden !important; */
}

.swimlane-slider-class .carousel__slide--hidden {
  /* background-color: red; */
}

/* .carousel .swimlane-slider-class img {
  width: auto !important;
  display: block !important;
} */
